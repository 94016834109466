<template>
  <WGContainer>
    <div class="wg-footer-menu-lists">

      <WGAccordion
        class="wg-footer-menu-list-item"
        :title="$t('footer.titleMenu1')"
        :isAccordion="isAccordion"
        v-model="activeAccordionOurDatingSite"
        accordionValue="1"
      >
        <template #content>
          <WGList
            :list="footerLinksOurDatingSite"
          >
            <template #item="{item}">
              <WGLink
                v-if="item.linkHref"
                target="_blank"
                type="router"
                :linkHref="item.linkHref"
              >
                {{$t(item.linkTitle)}}
              </WGLink>
              <p v-else>{{item}}</p>
            </template>
          </WGList>
        </template>
      </WGAccordion>

      <WGAccordion
        class="wg-footer-menu-list-item"
       :title="$t('footer.titleMenu2')"
        :isAccordion="isAccordion"
        v-model="activeAccordionAboutUs"
        accordionValue="2"
      >
        <template #content>
          <WGList
            :list="footerLinksAboutUs"
          >
            <template #item="{item}">
              <WGLink v-if="item.linkHref" type="router" :linkHref="item.linkHref">{{$t(item.linkTitle)}}</WGLink>
              <p v-else>{{item}}</p>
            </template>
          </WGList>
        </template>
      </WGAccordion>

      <WGAccordion
        class="wg-footer-menu-list-item"
        :title="$t('footer.titleMenu3')"
        :isAccordion="isAccordion"
        v-model="activeAccordionStartDating"
        accordionValue="3"
      >
        <template #content>
          <WGList
            :list="footerLinksStartDating"
          >
            <template #item="{item}">
              <WGLink
                v-if="item.linkHref"
                type="router"
                :linkHref="item.linkHref"
              >
                {{ $t(item.linkTitle) }}
              </WGLink>
              <p v-else>{{item}}</p>
            </template>
          </WGList>
        </template>
      </WGAccordion>

      <WGAccordion
        class="wg-footer-menu-list-item wg-footer-list-accept"
        :title="$t('footer.titleMenu4')"
        :isAccordion="false"
        v-model="activeAccordionAdditionally"
        accordionValue="4"
        :isShowTitle="!isAccordion"
      >
        <template #content>
          <div class="wg-footer-accept-content">
            <WGList
              class="wg-footer-logo-lists"
              :list="footerPayLogos"
              orientations="horizontal"
            >
              <template #item="{item}">
                <img :src="item.linkLogo" :alt="item.titleLogo">
              </template>
            </WGList>

            <i18n-t keypath="footer.payments" tag="p" scope="global" class="wg-footer-text">

              <template #actions1>
                <WGLink linkHref="https://pay.google.com/" variant="primary" linkTarget="_blank" isFontSizeInherit>
                  {{ $t('global.paymentList.listItem1') }}
                </WGLink>
              </template>

              <template #actions2>
                <WGLink linkHref="https://www.billing.creditcard/" variant="primary" linkTarget="_blank" isFontSizeInherit>
                  {{ $t('global.paymentList.listItem2') }}
                </WGLink>
              </template>
              <template #actions3>
                <WGLink linkHref="https://www.unlimit.com/" variant="primary" linkTarget="_blank" isFontSizeInherit>
                  {{ $t('global.paymentList.listItem3') }}
                </WGLink>
              </template>
              <template #actions4>
                <WGLink linkHref="https://cs.segpay.com/" variant="primary" linkTarget="_blank" isFontSizeInherit>
                  {{ $t('global.paymentList.listItem4') }}
                </WGLink>
              </template>

            </i18n-t>

            <WGSelectLocales></WGSelectLocales>

<!--            <WGButton variant="custom" size="sm" @click="clickStopWar">-->
<!--              <WGButtonTemplateStopWar/>-->
<!--            </WGButton>-->
          </div>
        </template>
      </WGAccordion>

    </div>

  </WGContainer>
</template>

<script>

import WGList from '@/components/UI/WGLists/WGList'
import WGLink from '@/components/UI/WGLinks/WGLink'
import WGAccordion from '@/components/UI/WGAccordions/WGAccordion'
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGSelectLocales from '@/components/UI/WGSelect/WGSelectLocales.vue'
import { inject, ref } from 'vue'
export default {
  name: 'WGFooterMenuLists',
  components: {
    WGSelectLocales,
    WGContainer,
    WGList,
    WGLink,
    WGAccordion
  },
  props: {
    isAccordion: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const activeAccordionOurDatingSite = ref('1')
    const activeAccordionAboutUs = ref('2')
    const activeAccordionStartDating = ref('3')
    const activeAccordionAdditionally = ref(null)
    const {
      footerLinksOurDatingSite,
      footerLinksAboutUs,
      footerLinksStartDating,
      footerPayLogos
    } = inject('$projectLinks')

    const clickStopWar = () => {
      window.open('https://stopwar.victoriya.club/', '_blank')
    }

    return {
      activeAccordionOurDatingSite,
      activeAccordionAboutUs,
      activeAccordionStartDating,
      activeAccordionAdditionally,
      footerLinksOurDatingSite,
      footerLinksAboutUs,
      footerLinksStartDating,
      footerPayLogos,
      clickStopWar
    }
  }
}
</script>
