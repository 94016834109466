<template>
    <div>
      <WGContainer is-clear-offsets>
        <WGBreadCrumbs :breadCrumbsList="breadCrumbsList" />
      </WGContainer>
      <WGContainer class="bg-white wg-page-search-container">
        <div class="wg-page-search-content">
          <div class="wg-page-search-head">
            <WGTitleMarkLetter>
             <template #slotTitle>
               <p class="wg-title-mark-letter_headline">
                 {{ $t('index.titleMark6') }}
               </p>
             </template>
            </WGTitleMarkLetter>
            <WGButton
              v-show="!isShowMenuFilter"
              @click="isOpen = !isOpen"
              class="wg-page-search_button"
              variant="primary"
            >
              <div class="wg-page-search_button">
                <svg class="wg-filter-icon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.2665 15.1999L16.4832 14.4166C16.8915 13.7999 17.1332 13.0583 17.1332 12.2583C17.1332 10.0916 15.3749 8.33325 13.2082 8.33325C11.0415 8.33325 9.2832 10.0916 9.2832 12.2583C9.2832 14.4249 11.0415 16.1833 13.2082 16.1833C14.0082 16.1833 14.7415 15.9416 15.3665 15.5333L16.1499 16.3166C16.3082 16.4749 16.5082 16.5499 16.7165 16.5499C16.9249 16.5499 17.1249 16.4749 17.2832 16.3166C17.5749 16.0166 17.5749 15.5166 17.2665 15.1999Z"/>
                  <path d="M16.3166 3.35008V5.20008C16.3166 5.87508 15.8999 6.71675 15.4833 7.14175L15.3333 7.27508C15.2166 7.38341 15.0416 7.40841 14.8916 7.35842C14.7249 7.30008 14.5583 7.25841 14.3916 7.21675C14.0249 7.12508 13.6333 7.08342 13.2333 7.08342C10.3583 7.08342 8.02493 9.41675 8.02493 12.2917C8.02493 13.2417 8.28327 14.1751 8.77493 14.9751C9.1916 15.6751 9.77493 16.2584 10.4083 16.6501C10.5999 16.7751 10.6749 17.0417 10.5083 17.1917C10.4499 17.2417 10.3916 17.2834 10.3333 17.3251L9.1666 18.0834C8.08327 18.7584 6.5916 18.0001 6.5916 16.6501V12.1917C6.5916 11.6001 6.25827 10.8417 5.92493 10.4251L2.7666 7.05842C2.34993 6.63342 2.0166 5.87508 2.0166 5.37508V3.43341C2.0166 2.42508 2.7666 1.66675 3.67493 1.66675H14.6583C15.5666 1.66675 16.3166 2.42508 16.3166 3.35008Z" />
                </svg>
                <span class="wg-page-search_title">
                  {{ $t('searchFilters.filterTitle') }}
                </span>
                <WGIcon icon="wg-ffi-arrow-down" />
              </div>
            </WGButton>
          </div>
          <div class="wg-page-search_body" v-if="countries">
            <WGFilterSearchGirls
              v-show="isOpen || isShowMenuFilter"
              @handleFilter="handleSubmitFilters"
              ref="filter"
              :current-page="currentPage"
              @close="isOpen = false"
              :class="{'wg-filter-search--active': isOpen}"
            />
            <div class="wg-page-search_body-view">
              <div v-if="!allGirlsList?.list?.data || !allGirlsList.list.data.length">
              {{ $t('searchFilters.emptyFields') }}
              </div>
              <WGPagination
                v-if="allGirlsList"
                :options="{...allGirlsList.list, current_page: currentPage }"
                @onChange="handleSubmitFilters({ page: $event, filters: $refs.filter.filterParams })"
              >
                <template v-slot="{ options }">
                  <WGGrid
                    :dataList="options"
                    :columns="4"
                    :columnsMd="3"
                    :columnsSm="3"
                    :columnsXs="2"
                    :gap="24"
                    :gapXs="16"
                  >
                    <template #item="{ item }">
                      <WGCardModel
                        :user="item"
                        :urlStorage="siteSettings?.settings?.url_storage"
                      />
                    </template>
                  </WGGrid>
                </template>
              </WGPagination>
              <div
                class="wg-page-search__seo-text"
                :class="{'wg-page-search__seo-text--hidden': textForSeo.length > 5000 && !isHiddenSeoText}"
                v-html="textForSeo"
              >
              </div>
              <WGLink type="button" isFontSizeInherit variant="primary" @click="onShowMore" v-if="textForSeo.length > 5000">
                <template v-if="!isHiddenSeoText">
                  {{ $t('global.showMore') }}
                </template>
                <template v-else>
                  {{ $t('global.showLess') }}
                </template>
              </WGLink>
            </div>
            <div
              v-if="loading"
              class="wg-page-search_loader"
            >
              <WGPreloader />
            </div>
          </div>
        </div>
      </WGContainer>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useBreakpointStore } from '@/controllers/components/breakpointMixin.js'
import WGIcon from '@/components/UI/WGIcons/WGIcon.vue'
import WGGrid from '@/components/UI/WGGrids/WGGrid.vue'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import WGCardModel from '@/components/UI/WGCards/WGCardModel.vue'
import WGPreloader from '@/components/UI/WGPreloader/WGPreloader.vue'
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGPagination from '@/components/UI/WGPagination/WGPagination.vue'
import WGBreadCrumbs from '@/components/UI/WGBreadCrumbs/WGBreadCrumbs.vue'
import WGTitleMarkLetter from '@/components/UI/WGTitleMarkLetter/WGTitleMarkLetter.vue'
import WGFilterSearchGirls from '@/components/Victoria/UI/WGFilter/WGFilterSearchGirls.vue'
import WGLink from '@/components/UI/WGLinks/WGLink'

export default {
  name: 'SearchGirls',
  components: {
    WGLink,
    WGGrid,
    WGCardModel,
    WGPagination,
    WGPreloader,
    WGFilterSearchGirls,
    WGIcon,
    WGButton,
    WGTitleMarkLetter,
    WGBreadCrumbs,
    WGContainer
  },
  setup () {
    const store = useStore()
    const perPage = 20
    const currentPage = ref(null)
    const pathParts = window.location.pathname.split('/')
    const pageIndex = pathParts.indexOf('page')

    if (pageIndex !== -1 && pageIndex + 1 < pathParts.length) {
      currentPage.value = parseInt(pathParts[pageIndex + 1], 10)
    } else {
      currentPage.value = 1
    }

    const breakpoint = useBreakpointStore()
    const isOpen = ref(false)
    const isHiddenSeoText = ref(false)

    store.dispatch('getCountries')
    getSeoTexts()
    getGirlsByPage()

    const countries = computed(() => store.getters.getCountriesList)

    const textForSeo = computed(() => {
      if (!dataForSeo.value) {
        return ''
      }
      const parser = new DOMParser()
      const parsedDocument = parser.parseFromString(Object.prototype.hasOwnProperty.call(dataForSeo.value, 'text') ? dataForSeo.value.text : dataForSeo.value, 'text/html')
      return parsedDocument.documentElement.innerHTML
    })

    const dataForSeo = computed(() => {
      return store.getters.getSeoTextForSearch
    })

    watch(dataForSeo, (newValue) => {
      if (newValue) {
        updateMetaDescriptionAndTitle(newValue.title, newValue.description)
      }
    })

    const isShowMenuFilter = computed(() => {
      return ['LG', 'XL'].includes(breakpoint.currentBreakpoint.value)
    })

    const breadCrumbsList = ref([
      { label: 'Home', url: '/' },
      { label: 'Search girls' }
    ])

    const updateMetaDescriptionAndTitle = (title, description) => {
      let newDescription = ''
      if (!title) {
        document.title = `Online Dating Brides from Different Countries | VictoriyaClub - page ${currentPage.value}`
      } else {
        document.title = `${title} - page ${currentPage.value}`
      }

      if (!description) {
        newDescription = `Looking for online single ladies? We are proud to introduce you our VictoryaClub dating site! Here you could find your Slavic Wife or Girlfriend! - page ${currentPage.value}`
      } else {
        newDescription = `${description} - page ${currentPage.value}`
      }

      const metaDescriptionTag = document.querySelector('meta[name="description"]')
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', newDescription)
      } else {
        const newMetaTag = document.createElement('meta')
        newMetaTag.setAttribute('name', 'description')
        newMetaTag.setAttribute('content', newDescription)
        document.head.appendChild(newMetaTag)
      }
    }
    updateMetaDescriptionAndTitle()
    const showLazyComponent = ref(false)
    const loading = ref(false)

    const allGirlsList = computed(() => {
      return store.getters.getGirlsList('all')
    })

    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })
    function getGirlsByPage (params) {
      return store.dispatch('apiGetGirlsList', {
        mode: 'all',
        perPage,
        page: currentPage.value,
        searchParams: params
      })
    }

    function getSeoTexts (countries = [], list = false) {
      let listOfCountries = []
      listOfCountries = countries.map(country => {
        const index = country.label.indexOf('(')

        if (index !== -1) {
          return country.label.substring(0, index).trim()
        } else {
          return country.label.trim()
        }
      })
      if (list) {
        store.dispatch('getTextByCountries', { countries: listOfCountries.toString() })
      } else {
        store.dispatch('getSearchSeoText', { unAuth: false, country: listOfCountries.toString() })
      }
    }

    function getAgesSeoTexts ({ ageMin, ageMax }) {
      store.dispatch('getTextByAge', `${ageMin},${ageMax}`)
    }

    const onShowMore = () => {
      isHiddenSeoText.value = !isHiddenSeoText.value
    }

    const apiParamsMap = {
      interest_marriage: 'serious_relationships',
      interest_companion: 'life_partner',
      interest_romantic: 'romantic_evening',
      interest_hookup: 'hookup',
      interest_online: 'online_friendship',
      interest_flirting: 'flirting',
      interest_naughty: 'naughty_talks',
      interest_travel: 'travel_companion',
      interest_moral: 'moral_support',
      interest_fun: 'have_fun'
    }

    const formatApiParams = (filters) => {
      const apiParams = {}
      for (const key in apiParamsMap) {
        if (Object.prototype.hasOwnProperty.call(apiParamsMap, key)) {
          apiParams[key] = filters.includes(apiParamsMap[key]) ? 1 : 0
        }
      }
      return apiParams
    }

    const updateURLWithPage = () => {
      let currentURL = window.history.state && Object.keys(window.history.state).length !== 0 ? window.history.state.current : window.location.pathname

      const urlParts = currentURL.split('/')

      const pageIndex = urlParts.findIndex(part => part === 'page')

      if (currentPage.value > 1) {
        if (pageIndex !== -1) {
          urlParts[pageIndex + 1] = currentPage.value
        } else {
          urlParts.push('page', currentPage.value)
        }
      } else {
        if (pageIndex !== -1) {
          urlParts.splice(pageIndex, 2)
        }
      }

      currentURL = urlParts.join('/')

      if (!currentURL) {
        currentURL = '/'
      }

      window.history.pushState({ current: currentURL }, '', currentURL)
      updateMetaDescriptionAndTitle()
    }

    const handleSubmitFilters = async ({ filters, page }) => {
      let params = {}
      if (page) {
        currentPage.value = page
      }
      updateURLWithPage()
      Object.keys(filters).forEach(key => {
        const value = filters[key]

        if (Array.isArray(value) && value.length) {
          if (key === 'countries' || key === 'cities') {
            params[key] = value.map(item => item.id).join(',')
          } else if (key === 'goals') {
            params = {
              ...params,
              ...formatApiParams(value)
            }
          } else {
            params[key] = value.join(',')
          }
        } else if (['new', 'online', 'camera'].includes(key)) {
          params[key] = value.value
        } else if (value !== null) {
          params[key] = value
        }
      })

      await getGirlsByPage(params)
      if (!store.getters.getGirlsList('all').list.data.length) {
        currentPage.value = 1
        await getGirlsByPage(params)
        updateURLWithPage()
      }

      if (+filters.age_min === 18 && +filters.age_max === 68) {
        filters.age_min = null
        filters.age_max = null
      }
      if (filters.countries.length > 1 && !filters.age_min) {
        await getSeoTexts(filters.countries, true)
      } else if (!filters.countries.length && filters.age_min && filters.age_max) {
        await getAgesSeoTexts({ ageMin: filters.age_min, ageMax: filters.age_max })
      } else if (filters.countries.length > 1 && filters.age_min) {
        await getSeoTexts()
      } else {
        await getSeoTexts(filters.countries)
      }
    }

    return {
      allGirlsList,
      breadCrumbsList,
      isOpen,
      showLazyComponent,
      loading,
      siteSettings,
      countries,
      currentPage,
      isShowMenuFilter,
      textForSeo,
      isHiddenSeoText,
      onShowMore,
      getGirlsByPage,
      handleSubmitFilters
    }
  }
}
</script>
